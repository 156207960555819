

































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType, AuditFlowScope,
  CmsCategoryDto,
  CmsContentDetailDto,
  CmsContentStatus
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import { AuditBodyDto } from "@/utils/customDto";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "cmsContentDetail",
  components: {
    AuditBlock,
    AttachmentsView
  },
  filters: {
    formatStatus(status?: string) {
      switch (status) {
        case "Draft":
          return "草稿";

        case "Published":
          return "已发布";

        case "Auditing":
          return "待审核";

        case "Reject":
          return "已拒绝";

        case "Deleted":
          return "已删除";
      }
    }
  }
})
export default class cmsContentDetail extends Vue {

  cmsContentId?: number;
  detail: CmsContentDetailDto = {
    cmsCategory: {}
  };
  baseURL: any;
  hostType = AttachmentHostType.CmsContentAttachment;
  loading = true;


  created() {
    if (this.$route.params.id) {
      this.cmsContentId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.cmsContent.getDetail({ id: this.cmsContentId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };



    });
  }

  // 下载附件
  downLoad(item: any) {
    window.location.href = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
  }

  get auditApi(){
    return {
      canAudit: api.cmsContent.canAudit,
      userAudit: api.cmsContent.audit
    } as AuditApi
  }



  // 返回
  private cancel() {
    this.$router.back();
  }
}
