













































import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import api from '@/api'
import {SlashScreenDto} from "@/api/appService";


@Component({
  name: 'SlashScreenManagement',
  components: {PagedTableView}
})
export default class SlashScreenManagement extends Vue {

  detailId = 0

  queryForm = {
    isActive: undefined,
    title: ''
  }

  fetchData(params: any) {
    return api.slashScreen.getAll(params)
  }

  handleCreate(){
    this.$router.push({name:'slashScreen-create'})
  }

  handleEdit(item: SlashScreenDto) {
    this.$router.push({name: 'slashScreen-edit', params: {id: item.id!.toString()}})
  }

  handleDetail(item: SlashScreenDto) {
    this.detailId = item.id!
    this.$router.push({name: 'slashScreen-detail', params: {id: item!.id!.toString()}})
  }

}

