


















































































import {Component, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import api from '@/api'
import {MusicMachineDonateDetailDto} from "@/api/appService";
import ImportExcel from "@/components/ImportExcel/index.vue";


@Component({
  name: 'MusicMachineDonateDetail',
  components: {
    PagedTableView,
    ImportExcel
  }
})
export default class MusicMachineDonateDetailManagement extends Vue {


  queryForm = {
    year: '',
    month: '',
    version: '',
    provinceName: '',
    cityName: '',
    areaName: ''
  }

  fetchData(params: any) {
    return api.musicMachineDonateDetail.getAll(params)
  }

  async handleDelete(index: number, row: MusicMachineDonateDetailDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.musicMachineDonateDetail
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

}

