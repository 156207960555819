






















































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  CmsFriendLinkDto,
  CmsFriendLinkDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditLink from "@/views/cms/friendLink/edit.vue";

@Component({
  name: "FriendLinkList",
  components: {
    EditLink,
    PagedTableView,
  },
})
export default class FriendLinkList extends Vue {
  queryForm = {
    name: "",
  };

  editLinkId = 0;

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.cmsFriendLink.getAll(params);
  }

  // 新建
  handleCreate() {
    this.editLinkId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: CmsFriendLinkDto) {
    this.editLinkId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  // 删除
  async handleDelete(index: number, row: CmsFriendLinkDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.cmsFriendLink
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
