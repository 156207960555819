









































































































































































































import api from "@/api";
import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {
  AuditFlowScope,
  CmsCategoryDto,
  CmsContentDto,
  CmsContentStatus
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import { createNgTree, INgNode } from "@/utils/tree";
import PreviewContent from "@/views/cms/content/components/previewContent.vue";

@Component({
  name: "CmsContentList",
  components: { AgileAuditTag, PagedTableView, PreviewContent }
})
export default class CmsContentList extends Vue {
  @Ref() readonly pagedTableView!: any;
  queryForm = {
    title: "",
    categoryId: undefined,
    status: undefined,
    enableDataPermissionLimit: false
  };
  categories: CmsCategoryDto[] = [];
  categoriesTree: INgNode<CmsCategoryDto>[] = [];
  fundId = 0;
  fundProjectId = 0;
  contentId = 0;

  @Watch("$route.params")
  changeRoute(newVal: any) {
    this.pagedTableView!.fetchData();
  }

  created() {
    if (this.$route.params.fundId) {
      this.fundId = Number(this.$route.params.fundId);
    }
    if (this.$route.params.fundProjectId) {
      this.fundProjectId = Number(this.$route.params.fundProjectId);
    }
    this.fetchCategories();
  }

  async fetchCategories() {
    await api.cmsCategory
      .getAll({ maxResultCount: 1000, needShowContentCategory: false })
      .then((res) => {
        this.categories = res.items!;
        this.categoriesTree = createNgTree(
          res.items!,
          "parentId",
          "id",
          null,
          "children",
          null,
          false,
          "id"
        );
      });
  }

  fetchData(params: any) {
    params.enableDataPermissionLimit = false;
    params.sorting = "lastest";
    if (this.$route.params.fundId) {
      params.fundId = Number(this.$route.params.fundId);
    }
    if (this.$route.params.fundProjectId) {
      params.fundProjectId = Number(this.$route.params.fundProjectId);
    }

    return api.cmsContent.getAll(params);
  }

  // 新建
  handleCreate() {
    if (this.fundId || this.fundProjectId) {
      this.$router.push({
        name: "content-create-other",
        params: {
          fundId: this.fundId.toString(),
          fundProjectId: this.fundProjectId.toString()
        }
      });
    } else {
      this.$router.push({
        name: "content-create"
      });
    }
  }

  // 状态
  formatStatus(value: any, column: any, cellValue: any) {
    switch (cellValue) {
      case CmsContentStatus.Draft:
        return "草稿";
      case CmsContentStatus.Published:
        return "已发布";
    }
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "publish":
        this.handlePublish($row.item);
        break;

      case "cancelPublish":
        this.handleCancelPublish($row.item);
        break;

      case "moveToTrash":
        this.handleMoveToTrash($row.item);
        break;

      case "stickyPost":
        this.handleStickyPost($row.item);
        break;

      case "cancelStickyPost":
        this.handleCancelStickyPost($row.item);
        break;

      case "previewContent":
        this.handlePreview($row.item);
        break;
    }
  }

  // 跳转详情页
  handleDetail(item: CmsContentDto) {
    this.$router.push({
      name: "content-detail",
      params: { id: item.id!.toString() }
    });
  }

  // 跳转编辑页
  handleEdit(index: number, item: CmsContentDto) {
    this.$router.push({
      name: "content-edit",
      params: {
        id: item.id!.toString() ?? "",
        fundId: this.fundId.toString() ?? ""
      }
    });
  }

  // 发布
  handlePublish(item: CmsContentDto) {
    this.$confirm("确定发布吗?", "提示").then(() => {
      api.cmsContent.publish({ body: { id: item.id } }).then((res) => {
        this.$message.success("发布成功");
      });
    });
  }

  // 取消发布
  handleCancelPublish(item: CmsContentDto) {
    this.$confirm("确定取消发布吗?", "提示").then(() => {
      api.cmsContent.cancelPublish({ body: { id: item.id } }).then((res) => {
        this.$message.success("取消成功");
      });
    });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.HelpApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  // 删除
  handleMoveToTrash(item: CmsContentDto) {
    this.$confirm("确定删除吗?", "提示").then(() => {
      api.cmsContent.moveToTrash({ body: { id: item.id } }).then((res) => {
        this.$message.success("删除成功");
      });
    });
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.categoryId = value![value.length - 1];
  }

  // 置顶
  handleStickyPost(item: CmsContentDto) {
    this.$confirm("确定置顶吗?", "提示").then(() => {
      api.cmsContent.stickyPost({ body: { id: item.id } }).then((res) => {
        this.$message.success("置顶成功");
      });
    });
  }

  // 取消置顶
  handleCancelStickyPost(item: CmsContentDto) {
    this.$confirm("确定取消置顶吗?", "提示").then(() => {
      api.cmsContent.cancelStickyPost({ body: { id: item.id } }).then((res) => {
        this.$message.success("取消成功");
      });
    });
  }

  //预览
  handlePreview(item: CmsContentDto) {
    this.contentId = item.id!;
    (this.$refs.editForm as any).show = true;
  }

  private cancel() {
    this.$router.back();
  }
}
