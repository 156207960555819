

































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import { CmsAnchorDto, CmsAnchorCreateOrUpdateDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditAnchor",
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: CmsAnchorCreateOrUpdateDto = {
    name: undefined,
    displayName: undefined,
    width: 0,
    height: 0,
    id: 0,
  };

  show = false;
  form: CmsAnchorCreateOrUpdateDto = { ...this.defaultData };

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.name}`;
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      if (this.dataId) {
        await api.cmsAnchor
          .get({ id: this.dataId })
          .then((res: CmsAnchorDto) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.cmsAnchor.update({
            body: { ...this.form },
          });
        } else {
          await api.cmsAnchor.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    name: [
      {
        required: true,
        message: "请输入编号",
        trigger: "blur",
      },
    ],
    displayName: [
      {
        required: true,
        message: "请输入锚点名称",
        trigger: "change",
      },
    ],
    width: [
      {
        type: "number",
        message: "宽度必须为数字",
        trigger: "change",
      },
      {
        type: "number",
        max: 10000,
        message: "范围在0-10000",
        trigger: "change",
      },
    ],
    height: [
      {
        type: "number",
        message: "高度必须为数字",
        trigger: "change",
      },
      {
        type: "number",
        max: 10000,
        message: "范围在0-10000",
        trigger: "change",
      },
    ],
  };
}
