








































import {Component, Vue} from "vue-property-decorator";
import {SlashScreenDto} from "@/api/appService";
import api from '@/api'

@Component({
  name: 'SlashScreenDetail'
})
export default class SlashScreenDetail extends Vue {


  detail: SlashScreenDto = {}
  loading = true;

  created() {
    if (this.$route.params.id) {
      this.fetchDetail()
    }
  }

  fetchDetail() {
    this.loading = true;
    api.slashScreen.get({id: Number(this.$route.params.id)}).then((detail) => {
      this.detail = detail
      this.loading = false
    })
  }

}

