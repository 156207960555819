











































import {Component, Ref, Vue} from "vue-property-decorator";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import {AttachmentHostType, SlashScreenCreateOrUpateDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import api from "@/api";

@Component({
  name: "SlashScreenEdit",
  components: {SimpleUploadImage}
})
export default class SlashScreenEdit extends Vue {

  @Ref('form') form!: ElForm;

  formData = {
    title: '',
    subTitle: "",
    startTime: undefined,
    endTime: undefined,
    displayOrder: undefined,
    imageUrl: undefined,
    isActive: true,
    id: 0,
    url: ''
  } as SlashScreenCreateOrUpateDto

  rules = {
    title: [
      {required: true, message: '标题是必须的'},
      {max: 30, message: '标题不能超过30个字符'}
    ],
    subTitle: [
      {max: 100, message: '标题不能超过100个字符'}
    ],
    startTime: [],
    endTime: []
  }

  created() {
    if (this.$route.params.id) {
      api.slashScreen.get({id: Number(this.$route.params.id)}).then(res => {
        this.formData = {...this.formData, ...res}
      })
    }
  }

  get title() {
    return this.formData.id ? '修改' : '新建'
  }

  get hostType() {
    return AttachmentHostType.SlashScreen
  }

  cancel() {
    this.$router.back()
  }

  save() {
    this.form.validate(isValid => {
      if (!isValid) {
        return
      }
      const saveAction = this.formData.id ? api.slashScreen.update : api.slashScreen.create
      saveAction({body: this.formData}).then(() => {
        this.$message.success('保存成功');
        this.$router.back();
      })
    })
  }
}

