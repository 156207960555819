

































































































































import api from "@/api";
import { Component, Vue } from "vue-property-decorator";
import AbSelect from "@/components/AbSelect/index.vue";
import {
  AlbumPhotoDto,
  AlbumPhotoMoveInput,
  RenameAlbumPhotoInput,
} from "@/api/appService";

@Component({
  name: "PhotoList",
  components: {
    AbSelect,
  },
})
export default class PhotoList extends Vue {
  uploadData = {
    albumId: 0,
  };
  photoData: AlbumPhotoMoveInput = {
    albumId: 1,
    id: 0,
  };
  uploadHeaders = {
    contentType: "multipart/form-data;",
    Authorization: "",
  };
  isShowUpdata = false;
  isShowMove = false;
  dialogImageUrl = "";
  dialogVisible = false;
  pagesize = 12;
  page = 1;
  totalCount = 0;
  albumList = [];
  albumDetail = {};
  list = [];
  queryForm = {
    albumId: 0,
    skipCount: 0,
    maxResultCount: 12,
  };
  form = {
    visible: false,
    submitting: false,
    data: {
      name: "",
      summary: "",
      id: 0,
    },
    rules: {
      name: [
        {
          required: true,
          message: "相册名称",
          trigger: "change",
        },
        {
          max: 100,
          message: "最多100个字符",
          trigger: "blur",
        },
      ],
    },
  };
  defineImg = require("@/assets/images/none-cover.png");
  rename: RenameAlbumPhotoInput = {
    name: "",
    id: 0,
  };
  selectedPhotos = "";
  selectedPhotoIds: any = [];
  albumId?: number;
  baseURL: any;

  created() {
    this.uploadData.albumId =
      this.queryForm.albumId =
      this.albumId =
        Number(this.$route.params.id);
    // this.queryForm.albumId = Number(this.$route.params.id);
    // this.uploadData.albumId = Number(this.$route.params.id);
    this.uploadHeaders.Authorization = `Bearer ${
      (this as any).$store.getters["user/token"]
    }`;

    this.fetchData(1);
    this.fetchAlbumList();
    this.fetchAlbumDetail();
  }

  //  获取所有相册列表
  fetchAlbumList() {
    api.album
      .getAllAlbums({
        skipCount: 0,
        maxResultCount: 65535,
      })
      .then((response) => {
        this.albumList = response.items as any;
        this.photoData.albumId = response.items![0].id as any;
      });
  }

  // 获取相册详情
  fetchAlbumDetail() {
    api.album.get({ id: Number(this.$route.params.id) }).then((res: any) => {
      this.albumDetail = res as any;
    });
  }

  // 获取照片
  fetchData(page: any) {
    this.selectedPhotoIds = [];
    this.selectedPhotos = "";
    //根据实际请求中的页码更新分页器每页数据条数
    var sessionPagesize = this.$getActualPageSize();
    if (sessionPagesize > 0) {
      this.pagesize = sessionPagesize;
    }
    if (!page) page = this.page;
    this.queryForm.skipCount = (page - 1) * this.pagesize;
    this.queryForm.maxResultCount = this.pagesize;
    api.album.getAllPhotos(this.queryForm as any).then((response) => {
      this.list = response.items as any;
      this.totalCount = response.totalCount as any;
      //根据实际请求中的页码更新分页器页码
      var actualPage = this.$getActualPageIndex();
      if (actualPage > 0) {
        this.page = actualPage;
      }
    });
  }

  current_change(e: number) {
    //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
    this.$clearHistorySearchParams();
    this.page = e;
    this.fetchData(e);
  }

  handleSizeChange(e: number) {
    //手动切换页码时清空上次请求缓存信息，确保以实际选择的页码进行请求
    this.$clearHistorySearchParams();
    this.pagesize = e;
    this.fetchData(1);
  }

  // 创建相册
  handleCreateNewRole() {
    this.form.data.name = "";
    this.form.data.summary = "";
    //   this.$refs.roleForm.resetFields()
    this.form.visible = true;
  }

  // 保存新建相册
  handleRoleSave() {
    (this.$refs.roleForm as any).validate((valid: any) => {
      if (valid) {
        this.form.submitting = true;
        var fn;
        if (this.form.data.id) {
          fn = api.album.update;
        } else {
          fn = api.album.create;
        }
        fn({ body: this.form.data }).then((res: any) => {
          this.form.submitting = false;
          this.form.visible = false;
          this.fetchAlbumList();
        });
      }
    });
  }

  // 删除
  handleDelete(item: AlbumPhotoDto, index: number) {
    this.$confirm("您确认要删除此相册吗", "删除提示").then(() => {
      this.onDelete(item, index);
    });
  }

  handleDownload(item: AlbumPhotoDto, index: number) {
    const id = item.id;
    /*api.album.download({
        id
    });*/
  }

  handleReview(item: AlbumPhotoDto, index: number) {
    window.open(item.photoPath, "_blank");
  }

  onDelete(item: AlbumPhotoDto, index: number) {
    const id = item.id;
    api.album
      .deletePhoto({
        id,
      })
      .then((res) => {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.fetchData(undefined);
      })
      .catch((error) => {
        console.log("delete catch");
      });
  }

  // 上传照片
  handleUpData() {
    this.isShowUpdata = true;
    if ((this.$refs.uploadPhoto as any) != undefined)
      (this.$refs.uploadPhoto as any).clearFiles();
  }

  //  上传
  handleAvatarSuccess(res: any, file: any) {
    this.fetchData(undefined);
  }

  beforeAvatarUpload(file: any) {
    //   this.uploadData.file = file
    //   const isLt2M = file.size / 1024 / 1024 < 2
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isLt2M
  }

  handleRemove(file: any, fileList: any) {
    this.onDelete(file.response.result, 0);
    console.log(file, fileList);
  }

  handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  // 移动到其他相册
  handleMove(item: AlbumPhotoDto, index: number) {
    this.photoData.id = item.id as any;
    this.isShowMove = true;
  }

  // 移动
  handleMoveToNewAlbum(item: AlbumPhotoDto, index: number) {
    api.album.moveToNewAlbum({ body: this.photoData }).then((res: any) => {
      this.$message({
        type: "success",
        message: "修改成功",
      });
      this.fetchData(undefined);
      this.isShowMove = false;
    });
  }

  // 设置为封面
  handleSetAsCover(item: AlbumPhotoDto, index: number) {
    api.album
      .setAsCover({
        id: item.id,
      })
      .then((res) => {
        this.$message({
          type: "success",
          message: "设置成功",
        });
        this.fetchAlbumDetail();
        this.isShowMove = false;
      });
  }

  // 重名照片
  handleRename(item: AlbumPhotoDto, index: number) {
    const id = item.id;
    var extension = ".";
    if (item.name !== undefined) {
      extension += item.name.split(".")[item.name.split(".").length - 1];
      console.log(extension);
    }

    this.$prompt("请输入照片名称", "提示", {}).then((x: any) => {
      this.rename.id = id;

      this.rename.name = x["value"] + extension;
      api.album
        .renamePhoto({
          body: this.rename,
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.fetchData(undefined);
        })
        .catch((error: any) => {
          console.log("delete catch");
        });
    });
  }

  jumpAlbum() {
    this.$router.push({
      name: "album-list",
    });
  }

  selectPhoto(id: number) {
    const index = this.selectedPhotoIds.indexOf(id);
    if (index != -1) {
      this.selectedPhotoIds.splice(index, 1);
    } else {
      this.selectedPhotoIds.push(id);
    }

    this.selectedPhotos = this.selectedPhotoIds.join(",");
  }

  downloadPhotos() {
    if (this.selectedPhotos) {
      window.location.href = `${this.baseURL}/api/services/app/Album/BatchDownload?albumId=${this.albumId}&albumPhotoId=${this.selectedPhotos}`;
    } else {
      this.$message({
        type: "error",
        message: "请先选择图片",
      });
    }
  }

  downloadAllPhotos() {
    if (this.list && this.list.length > 0) {
      let allPhotoIds: string[] = [];
      this.list.forEach((item: any) => {
        allPhotoIds.push(item.id);
      });
      const selectedPhotos = allPhotoIds.join(",");
      // console.log(`${this.baseURL}/api/services/app/Album/BatchDownload?albumId=${this.albumId}&albumPhotoId=${selectedPhotos}`)
      window.location.href = `${this.baseURL}/api/services/app/Album/BatchDownload?albumId=${this.albumId}&albumPhotoId=${selectedPhotos}`;
    } else {
      this.$message({
        type: "error",
        message: "该相册暂无图片",
      });
    }
  }

  handleArrowClick(item: any) {
    Vue.set(item, "isShow", !item.isShow);
    this.$nextTick();
  }
}
